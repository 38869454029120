import logo from "./logo.svg";
import QrReader from "react-qr-scanner";
import "./App.css";
import "antd/dist/antd.css";
import { useEffect, useState } from "react";

import {
  Route,
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  Divider,
  Button,
  Drawer,
  Select,
  Input,
  Collapse,
  Row,
  Col,
  DatePicker,
  Spin,
} from "antd";
import { unstable_renderSubtreeIntoContainer } from "react-dom";

const Medical = () => {
  let { userid_ } = useParams();
  const { Option } = Select;
  const [spinner, setSpinner] = useState(false);
  const [read, setRead] = useState(true);
  const [result, setResult] = useState();
  const [userId, setUserId] = useState(0);
  const [userData, setUserData] = useState({});
  const [test, setTest] = useState({
    testdate: "",
    testresult: "",
    vaccine: "",
    firstdose: "",
    seconddose: "",
    userid: "",
  });

  useEffect(() => {
    // make a call
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ userid: userid_ }),
    };
    fetch(
      "https://apec.borderpass.com/apecborderpassapi/clinicapi.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setVisible((preState) => (preState = false));
        setUserData((preState) => (preState = data));
        setTest((preState) => ({
          ...preState,
          userid: userid_,
        }));
        console.log(userId);
        console.log(data);
      });
  }, [userid_]);

  const [visible, setVisible] = useState(false);

  const onTestDate = (date, dateString) => {
    setTest((preState) => ({
      ...preState,
      testdate: dateString,
    }));
    console.log(test);
  };
  const handleTestResults = (value) => {
    setTest((preState) => ({
      ...preState,
      testresult: value,
    }));
  };
  const handleVaccine = (value) => {
    setTest((preState) => ({
      ...preState,
      vaccine: value,
    }));
  };
  const handleFirstDose = (date, dateString) => {
    setTest((preState) => ({
      ...preState,
      firstdose: dateString,
    }));
  };
  const handleSecondDose = (date, dateString) => {
    setTest((preState) => ({
      ...preState,
      seconddose: dateString,
    }));
  };

  const submit_ = () => {
    console.log(test);
    setSpinner((preState) => (preState = true));
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(test),
    };
    fetch(
      "https://apec.borderpass.com/apecborderpassapi/clinicupdate.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          alert("Data saved");
        } else {
          alert("Data could not be saved");
        }
        setSpinner((preState) => (preState = false));
      });
  };

  return (
    <Router>
      <div className="App">
        {/*  */}
        <Divider orientation="center" plain style={{ marginBottom: "10px" }}>
          User information
        </Divider>
        <div className="divContainer">
          <Row>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Name"
                value={userData.name}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Passport"
                value={userData.passport}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Country"
                value={userData.country}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Birthday"
                value={userData.birthdate}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Sex"
                value={userData.sex}
                disabled
              />
            </Col>
          </Row>
        </div>
        <div className="divContainer">
          <Row style={{ padding: "0px" }}>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Test Date"
                value={userData.testdate}
                disabled
              />
            </Col>

            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Test Result"
                value={userData.testresult}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Vaccine"
                value={userData.vaccine}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="First Dose"
                value={userData.firstdose}
                disabled
              />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Input
                style={{ margin: "0px" }}
                placeholder="Second Dose"
                value={userData.seconddose}
                disabled
              />
            </Col>
          </Row>
        </div>
        <Divider
          orientation="center"
          plain
          style={{ marginTop: "40px", marginBottom: "10px" }}
        >
          COVID-19 vaccination & tests
        </Divider>
        <div className="divContainer">
          <Row>
            <Col style={{ margin: "10px" }}>
              <DatePicker onChange={onTestDate} placeholder="Test date" />
            </Col>
            <Col style={{ margin: "10px" }}>
              <Select
                defaultValue="Test result"
                style={{ width: 120 }}
                onChange={handleTestResults}
              >
                <Option value="Positive">Positive</Option>
                <Option value="Negative">Negative</Option>
              </Select>
            </Col>
            <Col style={{ margin: "10px" }}>
              <Select
                defaultValue="Vaccine"
                style={{ width: 120 }}
                onChange={handleVaccine}
              >
                <Option value="AstraZeneca">AstraZeneca</Option>
                <Option value="Pfizer-BioNTech">Pfizer-BioNTech</Option>
                <Option value="Sinovac">Sinovac</Option>
                <Option value="CanSino Biologics">CanSino Biologics</Option>
                <Option value="Sputnik V">Sputnik V</Option>
              </Select>
            </Col>
            <Col style={{ margin: "10px" }}>
              <DatePicker onChange={handleFirstDose} placeholder="First dose" />
            </Col>
            <Col style={{ margin: "10px" }}>
              <DatePicker
                onChange={handleSecondDose}
                placeholder="Second dose"
              />
            </Col>
          </Row>
        </div>

        <div className="divContainer">
          <Button
            style={{ marginTop: "50px", fontWeight: 800 }}
            type="default"
            size={"large"}
            onClick={submit_}
          >
            <span style={{ marginRight: "5px" }}>Submit</span>
            {spinner && <Spin />}
          </Button>
        </div>
      </div>
    </Router>
  );
};

export default Medical;
