import logo from "./logo.svg";
import QrReader from "react-qr-scanner";
import "./App.css";
import "antd/dist/antd.css";
import { useState } from "react";
import Medical from "./medicalpartners";
import {
  Route,
  BrowserRouter as Router,
  useParams,
  useLocation,
} from "react-router-dom";
import {
  Divider,
  Button,
  Drawer,
  Select,
  Input,
  Collapse,
  Row,
  Col,
  DatePicker,
  Spin,
} from "antd";
import { unstable_renderSubtreeIntoContainer } from "react-dom";

function App() {
  return (
    <Router>
      <Route exact path="/medical/:userid_" component={Medical} />
    </Router>
  );
}

export default App;
